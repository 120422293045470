import { request } from "../request";

export const getHomeGoodPriceData = () => {
    return request({
        method: 'get',
        url: '/home/goodprice',
    })
}

export const getHomeHighScoreData = () => {
    return request({
        method: 'get',
        url: '/home/highscore',
    })
}

export function getHomeDiscountData() {
    return request({
        method: 'get',
        url: "/home/discount"
    })
}

export function getHomeHotRecommendData() {
    return request({
        method: 'get',
        url: "/home/hotrecommenddest"
    })
}

export function getHomeLongforData() {
    return request({
        method: 'get',
        url: "/home/longfor"
    })
}

export function getHomePlusData() {
    return request({
        method: 'get',
        url: "/home/plus"
    })
}