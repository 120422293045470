import React, { memo } from "react";
import { useRoutes } from "react-router-dom";

import routes from "./router";
import useScrollTop from "./hooks/useScrollTop";
const App = memo(() => {
  useScrollTop()

  return (
    <div className="app">
      {useRoutes(routes)}
    </div>
  );
});
export default App;
