import { createSlice } from "@reduxjs/toolkit"

const detailSlice = createSlice({
    name: "detail",
    initialState: {
        detailInfo: {
            "_id": "63043046432f9033d45410dc",
            "id": "49165669",
            "picture_url": "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/11aa11ee-e01f-4a53-8ba5-26de2e69502c.jpeg?aki_policy=large",
            "picture_urls": [
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/11aa11ee-e01f-4a53-8ba5-26de2e69502c.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/6cd1f284-c970-4822-b63e-74d9c8dcc188.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/b05f8093-d099-4556-bec1-05a123840565.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/affebcd2-2c92-47f8-89ef-6c6c10ee156a.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/350f6b5c-d1b2-4346-8708-c9a65fe388b9.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/dfa54e99-48c1-42e4-8875-b4b5d0be63fe.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/3250244b-dcb1-417d-8543-c51b65418d78.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/15cbbe6e-ee45-4da6-bbb6-05318eda48d7.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/9f0f1ad6-2074-4704-a3da-fb0976297cb6.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/a400860b-d132-44d3-b7c1-4c47a6640265.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/45e59644-bbc9-4fb4-a885-efae1f13d44f.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/846f059b-7821-4310-b92c-86a277285b41.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/f254f426-4de8-4c59-8db8-fbb48b2b4c38.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/ad1db07c-95dd-4041-9c3a-3c0c466d611a.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/456e8e25-c637-4752-a8e2-5256d713a7eb.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/f7875f98-63a4-4ded-ac6c-b4aa2374c6cd.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/1161ec7a-bf9c-44dd-b1eb-cebe0721796e.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/7f1b5682-09c2-457e-b183-6a7bd6703f90.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/7ff09eb9-af03-4d24-807b-c93ca0754e33.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/f81ddeb3-9d53-4250-9704-2a9349000b38.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/17ff1524-d2e9-4290-a3b8-24a24075c387.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/37912333-a59e-4227-84fd-686aa5e2ecb1.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/77062756-1379-47c6-b3b0-17ae44565eba.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/148b1051-e0eb-4d10-8f61-c3c107b1c41f.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/a81c7cf5-573b-441e-8003-f7c6d48c3c1a.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/8609c745-7754-40d6-a2c5-6ca4ef3c7271.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/33d49e74-e484-4c0e-9726-754e0047eaad.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/b8800a81-2929-4061-97e0-01b1993087d2.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/3de6b974-2c98-421e-82e2-bf51b886fda3.jpeg?aki_policy=large",
                "https://z1.muscache.cn/im/pictures/miso/Hosting-49165669/original/2a223b62-efab-4561-8ce2-37e4e2971d38.jpeg?aki_policy=large"
            ],
            "verify_info": {
                "messages": [
                    "整套公寓型住宅",
                    "1室1卫2床"
                ],
                "text_color": "#767676"
            },
            "name": "【轻奢Loft投影房&烟火】汉溪长隆、广州南站/番禺天河城百货~万达广场/南村万博地铁站、大学城",
            "price": 370,
            "price_format": "￥370",
            "star_rating": 5,
            "star_rating_color": "#FF5A5F",
            "reviews_count": 53,
            "reviews": [
                {
                    "comments": "房间空间很大，装修的风格很大气，床品有档次，当地的美食很多，在这玩的很开心",
                    "created_at": "2022-06-16T00:00:00Z",
                    "is_translated": false,
                    "localized_date": "2022年6月",
                    "reviewer_image_url": "https://a0.muscache.com/im/pictures/user/bd514790-41dd-4bd3-beb1-3ad2aea5152c.jpg?aki_policy=x_medium",
                    "review_id": 650154898638268400
                }
            ],
            "bottom_info": null,
            "lat": 23.01087,
            "lng": 113.34489,
            "image_url": "/moreitems/ad0e5254433cb33ad77a035475f10782.jpg"
        }
    },
    reducers: {
        changeDetailInfoAction(state, { payload }) {
            state.detailInfo = payload
        }
    }
})

export const { changeDetailInfoAction } = detailSlice.actions
export default detailSlice.reducer
