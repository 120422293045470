import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import { HashRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from 'react-redux'
import App from "@/App";

// import "normalize.css"
// import "antd/dist/antd.less"
// import "antd/dist/reset.css"

// import "@assets/css/index.less"

import theme from "./assets/theme";
import store from './store'
const root = ReactDOM.createRoot(document.querySelector('#root'))
root.render(
    <Provider store={store}>
        <Suspense fallback={<div>loading...</div>}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ThemeProvider>
        </Suspense>
    </Provider>
)