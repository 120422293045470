import { createSlice } from "@reduxjs/toolkit"


const mainSlice = createSlice({
    name: "main",
    initialState: {
        headerConfig: {
            isFixed: false,
            topAlpha: false
        }
    },
    reducers: {
        changeHeaderConfigAction(state, { payload }) {
            // state.headerConfig = payload
            console.log(payload)
            if (typeof payload === 'object') {
                Object.keys(payload).forEach(key => {
                    state.headerConfig[key] = payload[key]
                })
            } else {
                state.headerConfig.isFixed = !!payload
            }

        }
    }
})

export const { changeHeaderConfigAction } = mainSlice.actions
export default mainSlice.reducer
