import { lazy, cpnsLazy, Redirect } from './utils'
const AppContainer = cpnsLazy('app-container')
const Home = lazy('home')
const NotFound = lazy('404')
const Detail = lazy('detail')
const Entire = lazy('entire')
const routes = [
    {
        path: '/',
        element: <AppContainer />,
        children: [
            {
                path: '/',
                element: <Redirect to={'/home'}></Redirect>
            },
            {
                path: '/home',
                element: <Home />
            },
            {
                path: '/detail',
                element: <Detail />
            },
            {
                path: '/entire',
                element: <Entire />
            },
            {
                path: '*',
                element: <NotFound />

            }
        ]
    }
]
export default routes