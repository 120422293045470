import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './modules/counter'
import homeReducer from './modules/home'
import entireReducer from './modules/entire'
import detailReducer from './modules/detail'
import mainReducer from './modules/main'
const store = configureStore({
    reducer: {
        counter: counterReducer,
        home: homeReducer,
        entire: entireReducer,
        detail: detailReducer,
        main: mainReducer
    }
})
export default store