import { lazy } from './utils'
const NotFound = lazy('404')

const routes = [
    {
        path: '*',
        element: <NotFound />

    }
]
export default routes