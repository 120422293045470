
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHomeDiscountData, getHomeGoodPriceData, getHomeHighScoreData, getHomeHotRecommendData, getHomeLongforData, getHomePlusData } from '@/services/modules/home';

export const fetchHomeDataAction = createAsyncThunk('fetchdata', (payload, { dispatch }) => {
    getHomeGoodPriceData().then(res => {
        console.log('getHomeGoodPriceData...', res)
        dispatch(changeGoodPriceInfoAction(res))
    })
    getHomeHighScoreData().then(res => {
        console.log('changeGoodPriceInfoAction...', res)
        dispatch(changeHighScoreInfoAction(res))
    })
    getHomeDiscountData().then(res => {
        console.log('getHomeDiscountData...', res)
        dispatch(changeDsicountDataAction(res))
    })
    getHomeHotRecommendData().then(res => {
        dispatch(changeRecommendInfoAction(res))
    })
    getHomeLongforData().then(res => {
        dispatch(changeLongforInfoAction(res))
    })
    getHomePlusData().then(res => {
        dispatch(changePlusInfoAction(res))
    })
})

const homeSlice = createSlice({
    name: 'home',
    initialState: {
        goodPriceInfo: {},
        highScoreInfo: {},
        discountInfo: {},
        recommendInfo: {},
        longforInfo: {},
        plusInfo: {}
    },
    reducers: {
        changeGoodPriceInfoAction(state, { payload }) {
            state.goodPriceInfo = payload;
        },
        changeHighScoreInfoAction(state, { payload }) {
            state.highScoreInfo = payload;
        },
        changeDsicountDataAction(state, { payload }) {
            state.discountInfo = payload;
        },
        changeRecommendInfoAction(state, { payload }) {
            state.recommendInfo = payload
        },
        changeLongforInfoAction(state, { payload }) {
            state.longforInfo = payload
        },
        changePlusInfoAction(state, { payload }) {
            state.plusInfo = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHomeDataAction.fulfilled, (state, action) => {
            // console.log('fulfilled...', action)
            // console.log('fulfilled...', state)
        })
    }
})
export const {
    changeGoodPriceInfoAction,
    changeHighScoreInfoAction,
    changeDsicountDataAction,
    changeRecommendInfoAction,
    changeLongforInfoAction,
    changePlusInfoAction
} = homeSlice.actions
export default homeSlice.reducer

