
export function reducer(state, action) {
    switch (action.type) {
        case 'TEST': {
            return { ...state, ...action.data }

        }
        case 'FETCH_DATA': {
            return { ...state, id: action.id }
        }
        default: {
            return { ...state }
        }
    }
}

export default reducer